import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.quizApiBaseUrl}configurations`;
export default {
  fetchAllQuizConfiguration() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  updateQuizConfig(quizConfig) {
    const url = URL + `/${quizConfig.id}?_method=PUT`;
    return httpClient.post(url, quizConfig)
      .then(res => res)
      .catch(error => console.log(error));
  }
};
