<template>
  <div>
    <ag-table
      :data-source-api="fetchAllQuizResults"
      @emitDeleteMultipleRecords="deleteResult($event, null , true)"
      :grid-options="gridOptions"
      :rtl="false"
      :permissions="permissions"
      @emitExportToExcel="exportExcelFile"
    />
    <tm-popup
      :submit="onSubmit"
      title="تعديل معلومات الطالب"
      ref="popup"
    >
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="اسم الطالب"
            type="text"
            placeholder="اسم الطالب"
            validation-rules="required"
            v-model="ravenResult.student_name"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            name="تاريخ الولادة"
            type="date"
            validation-rules="required"
            v-model="ravenResult.birthdate"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="genderOptions"
            label="name"
            validation-rules="required"
            name="الجنس"
            :reduce="name => name.value"
            v-model="ravenResult.gender"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-input
            type="text"
            name="رقم الموبايل"
            validation-rules="required"
            v-model="ravenResult.phone_number"
            class="w-full"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="educationLevels"
            :reduce="option => option"
            label="name"
            validation-rules="required"
            name="المرحلة الدراسية"
            v-model="ravenResult.educational_level"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="classes"
            :reduce="option => option"
            label="name"
            validation-rules="required"
            name="الصف الدراسي"
            v-model="ravenResult.class"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="universityMajorOptions"
            :reduce="option => option"
            label="name"
            validation-rules=""
            name="التخصص الجامعي"
            v-model="ravenResult.university_major"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="supervisors"
            :reduce="option => option"
            label="name"
            validation-rules="required"
            name="اسم المشرف"
            v-model="ravenResult.supervisor_name"
          />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          class="mb-5"
        >
          <tm-select
            class="w-full"
            :options="types"
            :reduce="option => option"
            label="name"
            validation-rules="required"
            name="نوع الاختبار"
            v-model="ravenResult.categoryReqDto"
          />
        </vs-col>
      </vs-row>
    </tm-popup>
    <vs-popup
      title="تفاصيل نتيجة الطالب"
      :active.sync="popupActiveDetails"
    >
      <form>
        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الاسم: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.student_name }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>تاريخ الولادة: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.birthdate }}</h4>
            <br>
          </div>
          <div
            v-if="ravenResult.age"
            class="vx-col sm:w-1/3 w-full"
          >
            <h4>العمر: </h4>
          </div>
          <div
            v-if="ravenResult.age"
            class="vx-col sm:w-2/3 w-full mb-2"
          >
            <h4>{{ ravenResult.age }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الجنس: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.gender }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الصف الدراسي: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.class }}</h4>
            <br>
          </div>
          <div
            v-if="ravenResult.educational_level"
            class="vx-col sm:w-1/3 w-full"
          >
            <h4>المستوى التعليمي: </h4>
          </div>
          <div
            v-if="ravenResult.educational_level"
            class="vx-col sm:w-2/3 w-full mb-2"
          >
            <h4>{{ ravenResult.educational_level }}</h4>
            <br>
          </div>
          <div
            v-if="ravenResult.university_major"
            class="vx-col sm:w-1/3 w-full"
          >
            <h4>التخصص الجامعي: </h4>
          </div>
          <div
            v-if="ravenResult.university_major"
            class="vx-col sm:w-2/3 w-full mb-2"
          >
            <h4>{{ ravenResult.university_major }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>نوع الاختبار: </h4>
          </div>
          <div
            class="vx-col sm:w-2/3 w-full mb-2"
          >
            <h4>{{ ravenResult.categoryReqDto }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>اسم المشرف: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.supervisor_name }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>رقم الموبايل: </h4>
          </div>
          <div
            class="vx-col sm:w-1/3 w-full mr-1 mb-2"
            dir="ltr"
          >
            <h4>{{ ravenResult.phone_number }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>تاريخ الامتحان: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.exam_date }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>العلامة: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.mark }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>المدة: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.duration }}</h4>
            <br>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <h4>النتيجة: </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ ravenResult.result }}</h4>
            <br>
          </div>
          <div
            v-if="ravenResult.answers.true.length!== 0"
            class="vx-col sm:w-1/3 w-full mt-5"
          >
            <h4>الأجوبة الصحيحة: </h4>
          </div>
          <div
            v-if="ravenResult.answers.true.length!== 0"
            class="vx-col sm:w-2/3 w-full mb-2 mt-5"
          >
            <div class="vx-row">
              <div
                v-for="answer in ravenResult.answers.true"
                :key="answer.id"
              >
                <h4 class="mb-3">
                  {{ answer }},
                </h4>
              </div>
            </div>
          </div>
          <div
            v-if="ravenResult.answers.wrong.length!== 0"
            class="vx-col sm:w-1/3 w-full mt-5"
          >
            <h4>الأجوبة الخاطئة: </h4>
          </div>
          <div
            v-if="ravenResult.answers.wrong.length!== 0"
            class="vx-row mt-4 ml-5"
          >
            <div class="vx-col w-full mb-2 ml-5">
              <vs-table :data="ravenResult.answers.wrong">
                <template slot="thead">
                  <vs-th>السؤال</vs-th>
                  <vs-th>الجواب</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr
                    :key="index"
                    v-for="(info, index) in data"
                  >
                    <vs-td :data="data[index].question">
                      {{ info.question }}
                    </vs-td>
                    <vs-td :data="data[index].answer">
                      {{ info.answer }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
          <div
            v-if="ravenResult.answers.empty.length!== 0"
            class="vx-col sm:w-1/3 w-full mt-5"
          >
            <h4>الأجوبة الفارغة: </h4>
          </div>
          <div
            v-if="ravenResult.answers.empty.length!== 0"
            class="vx-col sm:w-2/3 w-full mb-2 mt-5"
          >
            <div class="vx-row">
              <div
                v-for="answer in ravenResult.answers.empty"
                :key="answer.id"
              >
                <h4 class="mb-3">
                  {{ answer }},
                </h4>
              </div>
            </div>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
import {RepositoryFactory} from "../../quiz/quiz-repositories/quizRepositoryFactory";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import TmSelect from "@/app/shared/shared-components/TmSelect";


const resultsRepo = RepositoryFactory.get('ravenResultsRepository');
const quizRepo = RepositoryFactory.get('ravenRepository');

export default {
  name: "RavenResults",
  components: {
    AgTable,
    TmPopup,
    TmInput,
    TmSelect
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      educationLevels: [],
      universityMajorOptions: [],
      classes: [],
      supervisors: [],
      types: [],
      ravenResult: {
        id: '',
        student_name: '',
        birthdate: '',
        gender: '',
        class: '',
        phone_number: '',
        exam_date: '',
        supervisor_name: '',
        educational_level: '',
        university_major: '',
        categoryReqDto: '',
        mark: '',
        duration: '',
        result: '',
        answers: {
          true: [],
          wrong: [],
          empty: []
        },
        type: '',
      },
      genderOptions: [
        {
          name: 'ذكر',
          value: 'male'
        },
        {
          name: 'أنثى',
          value: 'female'
        }
      ],
      popupActiveDetails: false,
      popupActive: false,
      filter: '',
      //TODO set permissions based on filter (kids-adults)
      permissions: {
        add: '',
        delete: 'destroy_raven_results_kids',
        edit: 'update_raven_results_kids'
      }
    };
  },
  watch: {
    '$route'() {
      this.getType();
      this.fetchAllQuizConfiguration();
      this.gridApi.refreshServerSideStore({purge: true});
    }
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          suppressSizeToFit: true,
          checkboxSelection: true,
        },
        {
          headerName: 'معرف الطالب',
          field: 'identifier',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الاسم',
          field: 'student_name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'تاريخ الولادة',
          field: 'birthdate',
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'العمر',
          field: 'age',
        },
        {
          headerName: 'الجنس',
          field: 'gender',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'المستوى التعليمي',
          field: 'educational_level',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الصف الدراسي',
          field: 'class',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'التخصص الجامعي',
          field: 'university_major',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'رقم الموبايل',
          field: 'phone_number',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'تاريخ الامتحان',
          field: 'exam_date',
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'اسم المشرف',
          field: 'supervisor_name',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'العلامة',
          field: 'mark',
          filter: 'agNumberColumnFilter'
        },
        {
          headerName: 'المدة',
          field: 'duration',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'النتيجة',
          field: 'result',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'لأجوبة الصحيحة',
          field: 'trueAnswers'
        },
        {
          headerName: 'الأجوبة الخاطئة',
          field: 'wrongAnswers'
        },
        {
          headerName: 'الأجوبة الفارغة',
          field: 'emptyAnswers'
        },
        {
          headerName: 'نوع الاختبار',
          field: 'category',
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'الإجراءات',
          valueGetter:
            function getObject(params) {
              return params.data.id;
            },
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (valueGetter, rowIndex) {
              self.deleteResult(valueGetter, rowIndex);
            },
            editRecord: function (ravenResult) {
              self.editRecord(ravenResult);
            },
            viewRecordDetails: function (ravenResult) {
              self.viewRecordDetails(ravenResult);
            },
            actions: ['delete', 'view', 'edit', 'raven']
          },
          sortable: false
        },
      ];
    },
    handleResponse() {
      this.popupActive = false;
      this.popupActiveDetails = false;
    },
    openPopup() {
      this.$refs.popup.open();
    },
    onSubmit() {
      this.updateResult(this.ravenResult);
    },
    editRecord(result) {
      Object.assign(this.ravenResult, result);
      this.openPopup();
    },
    deleteResult(ids, index, isMultiple = false) {
      if (isMultiple)
        ids = ids.join(',');
      resultsRepo.deleteResult(ids).then((response) => {
        if (response.code === 200) {
          this.gridApi.refreshServerSideStore({purge: true});
        }
      });
    },
    updateResult(result) {
      resultsRepo.updateResult(result).then((response) => {
        this.$refs.popup.endLoading();
        if (response.code === 200) {
          this.$refs.popup.close();
          this.gridApi.refreshServerSideStore({purge: true});
        }
      });
    },
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.ravenResult = record;
    },
    fetchAllQuizResults(query) {
      return resultsRepo.fetchAllQuizResults(this.filter, query).then((response => response));
    },
    exportExcelFile() {
      resultsRepo.exportResultsFromExcel();
    },
    getType() {
      if (this.$route.query.type !== undefined)
        this.filter = this.$route.query.type;
    },
    fetchAllQuizConfiguration() {
      quizRepo.fetchAllQuizConfiguration().then((response) => {
        response.forEach(item => {
          if (item.key === 'adults_education_levels')
            this.educationLevels = item.value;
          if (item.key === 'adults_university_major')
            this.universityMajorOptions = item.value;
          if (this.filter === 'Kids') {
            switch (item.key) {
              case 'kids_classes':
                this.classes = item.value;
                break;
              case 'kids_supervisor':
                this.supervisors = item.value;
                break;
              case 'kids_quiz_type':
                this.types = item.value;
            }
          } else {
            switch (item.key) {
              case 'adults_classes':
                this.classes = item.value;
                break;
              case 'adults_supervisor' :
                this.supervisors = item.value;
                break;
              case 'adults_quiz_type':
                this.types = item.value;
            }
          }
        });
      });
    },
  },
  created() {
    this.getType();
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllQuizConfiguration();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
