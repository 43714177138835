import ravenRepository from "./ravenRepository";
import ravenResultsRepository from "./ravenResultsRepository";

export const repositories = {
  ravenRepository,
  ravenResultsRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
