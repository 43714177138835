import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.quizApiBaseUrl}results`;
export default {
  fetchAllQuizResults(filter, query) {
    const url = URL + query + `&filter[type]=${filter}`;
    return httpClient.get(url)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  updateResult(result) {
    const url = URL + `/${result.id}?_method=PUT`;
    return httpClient.post(url, result)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  exportResultsFromExcel() {
    const url = URL + '/export';
    return httpClient.get(url, {responseType: 'arraybuffer'})
      .then(res => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Raven_Results.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => console.log(error));
  },
  deleteResult(ids) {
    return httpClient.delete(`${URL}/${ids}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }
};
